/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEdit, FaEnvelope, FaEye, FaTrash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { format } from "date-fns";
import { FaMessage, FaTicket } from "react-icons/fa6";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Swal from "sweetalert2";
import FormLoader from "./FormLoader";
import ScrollToTop from "./ScrollToTop";
import NoDataFound from "./NoDataFound";
import Header from "./Header";
import Config from "../API/Config";
import { useFormik } from "formik";
import * as Yup from "yup";
import Footer from "./Footer";
import SOLDOUT from "../Images/Sold-Out.png";
import { BsFillPrinterFill } from "react-icons/bs";
import {
  getEventBySponsorIdEventId,
  GetTicketDownloadById,
  SellTicketUSER,
  SendEmailAPI,
  SendSMSAPI,
} from "../API/GetAPI";

const SellTicket = () => {
  const SponsorData = Config.getSponsorData();
  const { Id } = useParams();
  const { SponsorId } = useParams();
  // ==================Event Data===============
  const [EventData, setEventData] = useState([]);
  const [BookData, setBookData] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [isEventValid, setIsEventValid] = useState(true);
  const fetchEventData = async () => {
    try {
      const result = await getEventBySponsorIdEventId(SponsorId, Id);
      setEventData(result[0]);
      setfilterdata(result[0].Bookings);
      setBookData(result[0].Bookings);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const Available = EventData?.AvailableQty;
  const validateEvent = (event) => {
    const currentDateTime = new Date(); // Get the current date and time

    const eventStartDateTime = new Date(
      `${event.Dates?.EventDate} ${event.Dates?.EventStartTime}`
    );
    const eventEndDateTime = new Date(
      `${event.Dates?.EventDate} ${event.Dates?.EventEndTime}`
    );

    // Check if current time is within event time range
    if (currentDateTime > eventEndDateTime) {
      setIsEventValid(false); // Event is over
    } else {
      setIsEventValid(true); // Event is valid
    }
  };

  const HandlePrint = async (Id) => {
    setIsFormLoading(true);
    try {
      const result = await GetTicketDownloadById(Id);
      if (result) {
        const pdfUrl = result.downloadUrl;
        window.open(pdfUrl, "_blank");
      } else {
        console.error("PDF generation failed:", result.data.message);
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  useEffect(() => {
    if (EventData) {
      formik.setValues({
        AddressId: EventData?.AddressId || "",
        VendorId: EventData?.VendorId || "",
        DateId: EventData?.DateId || "",
        EventId: EventData?.EventId || "",
        SponsorId: SponsorId,
        Phone: formik.values.Phone, // Keep previous phone value if already set
        Qty: formik.values.Qty, // Keep previous qty value if already set
      });
    }
  }, [EventData]); // Monitor EventData and update form values when it's set

  const [isFormLoading, setIsFormLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    Qty: Yup.number()
      .typeError("Ticket Quantity must be a number")
      .required("Ticket Quantity is required")
      .positive("Ticket Quantity must be positive")
      .integer("Ticket Quantity must be an integer")
      .max(Available, `Ticket Quantity cannot exceed ${Available}`),
    Phone: Yup.string()
      .matches(/^[0-9]+$/, "Mobile Number must be a number")
      .required("Mobile Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      EventId: "",
      SponsorId: SponsorId,
      AddressId: "",
      VendorId: "",
      DateId: "",
      Phone: "",
      Qty: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const result = await SellTicketUSER(values);
        if (result.status === true) {
          actions.resetForm();
          fetchEventData();
        }
      } catch (error) {
        console.error("Error", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const actionTemplate = (rowData) => {
    return (
      <div>
        <img src={rowData.QRCODE} alt="QR Code" className="w-28" />
      </div>
    );
  }; 

  const HandleTextSMS = async (OrderId) => {
    setIsFormLoading(true);
    try {
      const result = await SendSMSAPI(OrderId);
    } catch (error) {
      console.error("Error sending SMS:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  }; 
  useEffect(() => {
    const mySearch = EventData.Bookings?.filter(
      (item) =>
        item.Bookings && item.Bookings.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <Header />
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto  py-5 pb-16">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5 py-3">
            <div className=" flex-auto">
              <div className="px-2">
                <div className="flex items-center  bg-black py-2 mb-2">
                  <Link to={"/dashboard"}>
                    <button
                      type="button"
                      className="flex text-white bg-green-500 dark:border-white dark:border px-6 py-1 my-1 ml-2 rounded-full"
                    >
                      Back
                    </button>
                  </Link>
                  <h1 className="text-lg ml-auto pt-0 text-white pr-3">
                    {EventData?.Event?.EventName}
                  </h1>
                </div>
              </div>
              {loading ? (
                <div className="flex justify-center items-center my-40">
                  <ClipLoader color={"#DC3558"} loading={loading} size={40} />
                </div>
              ) : (
                <div className=" px-2">
                  <div className="bg-white  border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                    <div className="grid gap-6 md:grid-cols-2">
                      <div>
                        <div className="bg-white dark:bg-boxdark-3 border border-gray-200 dark:border-zinc-600 rounded-lg shadow-lg overflow-hidden">
                          <img
                            src={EventData?.Event?.Banner}
                            alt={EventData?.Event?.EventName}
                            className="w-full h-48 object-cover"
                          />
                          <div className="p-4">
                            <h2 className="text-xl font-bold text-gray-800 dark:text-white mb-2">
                              {EventData?.Event?.EventName}
                            </h2>
                            <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">
                              Event Date: {format(new Date(EventData?.Dates?.EventDate),"dd/MM/yyyy")}
                            </p>

                            {/* Available Ticket Quantity */}
                            <div className="flex items-center mb-2">
                              <FaTicket className="text-themecolor1 mr-2" />
                              <span className="text-lg font-semibold">
                                Available Tickets: {EventData?.AvailableQty}
                              </span>
                            </div>

                            {/* Country Info */}
                            <div className="text-sm text-gray-500 dark:text-gray-400">
                              <span>
                                Location: {EventData?.Address?.Location}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="border-b flex items-center bg-themecolor1 py-2">
                          <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                            Sell Ticket
                          </h1>
                        </div>
                        <div className="grid grid-cols-1 gap-5">
                          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                            {isEventValid && EventData?.AvailableQty > 0 ? (
                              <form onSubmit={formik.handleSubmit}>
                                <div className="container mx-auto border my-2 p-4">
                                  <div className="grid md:grid-cols-1 grid-cols-1 gap-3">
                                    <div>
                                      <h1 className="mb-2 text-sm">
                                        Mobile Number
                                        <span className="text-red-600">*</span>
                                      </h1>
                                      <input
                                        type="text"
                                        name="Phone"
                                        value={formik.values.Phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                                        placeholder="Mobile Number"
                                      />
                                      {formik.touched.Phone &&
                                      formik.errors.Phone ? (
                                        <div className="text-red-500 text-xs mt-2">
                                          {formik.errors.Phone}
                                        </div>
                                      ) : null}
                                    </div>

                                    <div>
                                      <h1 className="mb-2 text-sm">
                                        Ticket Quantity
                                        <span className="text-red-600">*</span>
                                      </h1>
                                      <input
                                        type="text"
                                        name="Qty"
                                        value={formik.values.Qty}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        className="border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
                                        placeholder="Sponsor Ticket Quantity"
                                      />
                                      {formik.touched.Qty &&
                                      formik.errors.Qty ? (
                                        <div className="text-red-500 text-xs mt-2">
                                          {formik.errors.Qty}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <p className="mx-4 font-normal text-sm">
                                  <b>Note:</b> Please sell the sponsor ticket.
                                  Once sell, it cannot be edited or deleted.
                                </p>
                                <div className="flex items-center my-2">
                                  <button
                                    type="submit"
                                    className="bg-gradient-to-r from-pink-500 to-red-500 shadow-lg hover:scale-105 transition-all block md:my-3 placeholder:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto"
                                  >
                                    Sell
                                  </button>
                                </div>
                              </form>
                            ) : (
                              <img
                                src={SOLDOUT}
                                alt=""
                                className="w-96 mx-auto my-9"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <>
                      {BookData?.length === 0 ? (
                        <NoDataFound />
                      ) : (
                        <div className="mt-5">
                          <div className="border-b flex items-center bg-themecolor1 py-2">
                            <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                              Selled Ticket
                            </h1>
                          </div>
                          <DataTable
                            value={filterdata}
                            tableStyle={{
                              minWidth: "50rem",
                              border: "1px solid #e0e0e0",
                            }}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            emptyMessage="No Data found"
                            globalFilter={search}
                            header={
                              <div className="flex justify-between p-ai-center">
                                <span className="p-input-icon-left">
                                  <i className="pi pi-search" />
                                  <InputText
                                    type="text"
                                    className="text-start me-auto border-2 py-3 text-sm pl-2 md:pr-20 pr-5"
                                    onInput={(e) => setsearch(e.target.value)}
                                    placeholder="Search"
                                  />
                                </span>
                              </div>
                            }
                          >
                            <Column
                              header="#"
                              className="border border-stroke p-1 px-2 "
                              body={(rowData, { rowIndex }) => rowIndex + 1}
                            />

                            <Column
                              field="Location"
                              header="Location"
                              className="border border-stroke p-1 px-2 "
                            />
                            <Column
                              field="EventDate"
                              header="Event Date"
                              sortable
                              className="border border-stroke p-1 px-2 "
                              body={(rowData) =>
                                format(
                                  new Date(rowData.EventDate),
                                  "dd/MM/yyyy"
                                )
                              }
                            />
                            <Column
                              field="BookForDate"
                              header="BookForDate"
                              className="border border-stroke p-1 px-2 "
                              body={(rowData) =>
                                format(
                                  new Date(rowData.BookForDate),
                                  "dd/MM/yyyy"
                                )
                              }
                            />
                            <Column
                              field="EventStartTime"
                              header="EventStartTime"
                              className="border border-stroke p-1 px-2 "
                            />
                            <Column
                              field="EventEndTime"
                              header="EventEndTime"
                              className="border border-stroke p-1 px-2 "
                            />
                            <Column
                              field="Phone"
                              header="Phone"
                              className="border border-stroke p-1 px-2 "
                            />
                            <Column
                              field="Qty"
                              header="Qty"
                              className="border border-stroke p-1 px-2 "
                            />

                            <Column
                              header="View"
                              className="border border-stroke p-1 px-2 "
                              body={actionTemplate}
                            />
                            <Column
                              header="Actions"
                              className="border border-stroke p-1 px-2 "
                              body={(rowData) => (
                                <>
                                  <button
                                    className="ml-auto mx-2 bg-green-500 text-white p-2 rounded-full"
                                    onClick={() => HandlePrint(rowData.Id)}
                                  >
                                    <BsFillPrinterFill className="text-2xl text-white" />
                                  </button> 
                                  <button
                                    className="ml-auto mx-2 bg-orange-500 text-white p-2 rounded-full" 
                                    onClick={() => HandleTextSMS(rowData.Id)}
                                  >
                                    <FaMessage className="text-2xl text-white" />
                                  </button>
                                </>
                              )}
                            />
                          </DataTable>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SellTicket;
