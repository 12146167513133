import React from "react";
import NoData from "../Images/nodata.png";

const NoDataFound = () => {
  return (
    <div className="mx-auto bg-white dark:bg-boxdark font-bold text-2xl text-bodydark2 text-center">
      <img src={NoData} alt="" className="mx-auto my-auto " />
    </div>
  );
};

export default NoDataFound;
