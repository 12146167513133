import React, { useEffect, useState } from "react";
import logo from "../Images/newlogo.png";
import BG from "../Images/bg.jpg";
import { useFormik } from "formik";
import * as yup from "yup";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router";
import FormLoader from "./FormLoader";

const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 characters")
    .required("OTP is required"),
});
const validationSchema = yup.object().shape({
  EOP: yup
    .string()
    .required("Phone number is required")
    .min(10, "Phone number must be exactly 10 digits")
    .max(10, "Phone number must be exactly 10 digits"),
});

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);

  const [showResend, setShowResend] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  // -----------------------otpsend-------------------------
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Type: "S",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try { 
        const response = await sendOTP(values);
        if (response.status === true) { 
          setOtpSent(true);
        }
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  const navigate = useNavigate();
  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Type: "S",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          navigate("/dashboard");
          window.location.reload();
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Type: "S",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const Oneback = () => {
    setOtpSent(false);
  };

  return (
    <div
      className="bg-cover bg-center flex items-center justify-center min-h-screen"
      style={{ backgroundImage: `url(${BG})` }}
    >
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="bg-white bg-opacity-90 p-8 rounded-lg shadow-lg max-w-md w-full mx-5">
        <img src={logo} alt="Logo" className="w-52 mx-auto mb-5" />
        <h2 className="text-2xl font-bold mb-6 text-center">Login with OTP</h2>

        {!otpSent ? (
          <>
            <form onSubmit={formik.handleSubmit}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Phone Number
              </label>
              <div className="mb-4">
                <input
                  type="text"
                  name="EOP"
                  autoFocus
                  value={formik.values.EOP}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                  placeholder="Enter your phone number"
                />
                {formik.touched.EOP && formik.errors.EOP ? (
                  <div className="text-red-500 text-xs">
                    {formik.errors.EOP}
                  </div>
                ) : null}
                {/* <div className="text-center text-xs mt-3">
                  {showResend ? (
                    <>
                      OTP expired?{" "}
                      <button
                        onClick={handleResendOTP}
                        type="button"
                        className="underline text-themecolor1"
                      >
                        Resend OTP
                      </button>
                    </>
                  ) : (
                    `Resend OTP in ${formatTimer()}`
                  )}
                </div>*/}
              </div>
              <button className="w-full bg-indigo-600 text-white py-3 rounded-lg hover:bg-indigo-700 transition duration-200">
                Send OTP
              </button>
            </form>
          </>
        ) : (
          <>
            <form onSubmit={formik2.handleSubmit}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Enter OTP
              </label>
              <div className="mb-4">
                <span className="text-sm flex justify-between">
                  Mobile No is {formik2.values.EOP}
                  <span className="cursor-pointer" onClick={Oneback}>
                    {" "}
                    <FaEdit className="text-themecolor1" />
                  </span>
                </span>
                <input
                  type="text"
                  autoFocus
                  name="OTP"
                  value={formik2.values.OTP}
                  onChange={formik2.handleChange}
                  onBlur={formik2.handleBlur}
                  className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none"
                  placeholder="Enter OTP"
                />
                {formik2.touched.OTP && formik2.errors.OTP ? (
                  <div className="text-red-500 text-xs">
                    {formik2.errors.OTP}
                  </div>
                ) : null}
              </div>
              <button className="w-full bg-green-600 text-white py-3 rounded-lg hover:bg-green-700 transition duration-200">
                Verify OTP
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
