import axios from "axios";
import { toast } from "react-toastify";
import Config from "./Config";

const { API_BASE_URL } = Config; // Destructure the values from the config file

// ===================sendOTP================
export const sendOTP = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sponsor/send-otp`, data);
    if (response.data.status === true) {
      toast.success("Otp Send Successfully...");
      return response.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};
export const verifyOTP = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/sponsor/verify-otp`,
      data
    );
    if (response.data.status === true) {
      toast.success("Otp Verification Successfully...");
      sessionStorage.setItem(
        "SponsorToken",
        JSON.stringify(response.data.token)
      );
      sessionStorage.setItem(
        "SponsorData",
        JSON.stringify(response.data.responsedata[0])
      );
      console.log(response.data);
      return response.data;
    } else {
      toast.error("Invalid OTP");
    }
  } catch (error) {
    throw error;
  }
};
