/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormLoader from "./FormLoader";
import ScrollToTop from "./ScrollToTop";
import NoDataFound from "./NoDataFound";
import Header from "./Header";
import Config from "../API/Config";
import Footer from "./Footer";
import { getEventBySponsorId } from "../API/GetAPI";
import { format } from "date-fns";

const Dashboard = () => {
  const SponsorData = Config.getSponsorData();
  // ==================Event Data===============
  const [EventData, setEventData] = useState([]);
  const [filterdata, setfilterdata] = useState([]);
  const [search, setsearch] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const fetchEventData = async () => {
    try {
      const result = await getEventBySponsorId(SponsorData.Id);
      setEventData(result);
      setfilterdata(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  // -------------------delete venue------------------

  const actionTemplate2 = (rowData) => {
    return (
      <div>
        <Link to={`/sponsor/sell/${rowData.SponsorId}/${rowData.Id}`}>
          <div className=" bg-green-600 cursor-pointer my-2 text-white text-center py-1.5 rounded px-3">
            Sell
          </div>
        </Link>
      </div>
    );
  };
  useEffect(() => {
    const mySearch = EventData?.filter(
      (item) =>
        item.Event.EventName &&
        item.Event.EventName.toLowerCase().match(search.toLowerCase())
    );
    setfilterdata(mySearch);
  }, [search]);

  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <Header />
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto  py-5">
        <div className="text-2xl text-center">Welcome {SponsorData.Name}</div>
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-5 py-3">
            <div className=" flex-auto">
              <div className=" px-2">
                <div className="bg-white  border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      Your Listing
                    </h1>
                  </div>

                  {loading ? (
                    <div className="flex justify-center items-center my-40">
                      <ClipLoader
                        color={"#DC3558"}
                        loading={loading}
                        size={40}
                      />
                    </div>
                  ) : (
                    <>
                      {EventData?.length === 0 ? (
                        <NoDataFound />
                      ) : (
                        <>
                          <DataTable
                            value={filterdata}
                            tableStyle={{
                              minWidth: "50rem",
                              border: "1px solid #e0e0e0",
                            }}
                            paginator
                            rows={10}
                            rowsPerPageOptions={[5, 10, 25]}
                            emptyMessage="No Data found"
                            globalFilter={search}
                            header={
                              <div className="flex justify-between p-ai-center">
                                <span className="p-input-icon-left">
                                  <i className="pi pi-search" />
                                  <InputText
                                    type="text"
                                    className="text-start me-auto border-2 py-3 text-sm pl-2 md:pr-20 pr-5"
                                    onInput={(e) => setsearch(e.target.value)}
                                    placeholder="Search"
                                  />
                                </span>
                              </div>
                            }
                          >
                            <Column
                              header="#"
                              className="border border-stroke"
                              body={(rowData, { rowIndex }) => rowIndex + 1}
                            />

                            <Column
                              field="Event.EventName"
                              header="Event Name"
                              sortable
                              className="border border-stroke"
                            />
                            <Column
                              field="Address.Location"
                              header="Event Location"
                              className="border border-stroke"
                            />

                            <Column
                              field="Dates.EventDate"
                              header="Event Date"
                              className="border border-stroke"
                              body={(rowData) =>
                                format(
                                  new Date(rowData.Dates.EventDate),
                                  "dd/MM/yyyy"
                                )
                              }
                            />
                            <Column
                              field="Dates.EventStartTime"
                              header="Event Start Time"
                              className="border border-stroke"
                            />
                            <Column
                              field="Dates.EventEndTime"
                              header="Event End Time"
                              className="border border-stroke"
                            />
                            <Column
                              field="Qty"
                              header="Assign Quantity"
                              className="border border-stroke"
                            />

                            <Column
                              header="Sell Ticket"
                              className="border border-stroke"
                              body={actionTemplate2}
                            />
                          </DataTable>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
