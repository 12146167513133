const Config = {
  API_BASE_URL: "https://bme.seawindsolution.ae/api",
  getToken: () => {
    const sessiondata = sessionStorage.getItem("SponsorToken");

    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    // const token = parsedSessionData ? parsedSessionData.token : null;
    return parsedSessionData;
  },
  getId: () => {
    const sessiondata = sessionStorage.getItem("SponsorData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    const Id = parsedSessionData ? parsedSessionData.Id : null;
    return Id;
  },
  getType: () => {
    const sessiondata = sessionStorage.getItem("SponsorData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    const Type = parsedSessionData ? parsedSessionData.Type : null;
    return Type;
  },
  getSponsorData: () => {
    const sessiondata = sessionStorage.getItem("SponsorData");
    const parsedSessionData = sessiondata ? JSON.parse(sessiondata) : null;
    return parsedSessionData;
  },
};

export default Config;
