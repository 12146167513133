import React from "react";
import logo from "../Images/newlogo.png"; // Make sure to update the path to your logo
import { CiLogin } from "react-icons/ci";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.removeItem("SponsorToken");
        sessionStorage.removeItem("SponsorData");
        navigate("/");
      }
    });
  };

  return (
    <header className="bg-white shadow-md py-4 px-6 flex justify-between items-center">
      {/* Logo Section */}
      <div className="flex items-center">
        <img src={logo} alt="Logo" className="w-48 h-auto" />
      </div>

      {/* Log Out Section */}
      <button onClick={handleLogout} className="">
        <CiLogin className="text-3xl" />
      </button>
    </header>
  );
};

export default Header;
