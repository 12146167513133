import axios from "axios";
import Config from "./Config";
import { toast } from "react-toastify";

const { API_BASE_URL } = Config;
const token = Config.getToken();

const headers = {
  Authorization: `Bearer ${token}`,
};

// https://bme.seawindsolution.ae/api/sponsor/event/2
export const getEventBySponsorId = async (SponId) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/sponsor/event/${SponId}`,
      { headers }
    );
    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      console.error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// https://bme.seawindsolution.ae/api/sponsor/event/2
export const getEventBySponsorIdEventId = async (SponId, Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/sponsor/event/sell/${SponId}/${Id}`,
      { headers }
    );
    if (response.data.status === true) {
      return response.data.responsedata;
    } else {
      console.error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// https://bme.seawindsolution.ae/api/sponsor/event/book
export const SellTicketUSER = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/sponsor/event/book`,
      data,
      { headers }
    );
    if (response.data.status === true) {
      toast.success("Ticket Sell Successfully...");
      return response.data;
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    throw error;
  }
};

// https://bme.seawindsolution.ae/api/sponsor/event/book/pdf/7
export const GetTicketDownloadById = async (Id) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/sponsor/event/book/pdf/${Id}`,
      {
        headers,
      }
    );
    if (response.data.status === true) {
      return response.data; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};


// ===================SendSMSAPI=====================
// event/book/sms/:OrderId
export const SendSMSAPI = async (OrderId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/sponsor/event/book/sms/${OrderId}`, {
      headers,
    });
    if (response.data.status === true) {
      toast.success(response.data.message)
      return response.data; // Return response data
    } else {
      throw new Error(response.data.message); // Throw error with API message
    }
  } catch (error) {
    throw error; // Rethrow the error for further handling
  }
};
 