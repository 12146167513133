import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="md:py-6 py-2 bg-slate-100 dark:bg-boxdark w-full text-center mx-auto loader-first1 mt-auto bottom-0 fixed">
      © {currentYear} Love My Show Design and Develop By {"  "}
      <Link
        target="_blank"
        to={"https://www.seawindsolution.com/"}
        className="text-[#29aae1] ml-1"
      >
        Seawind Solution Pvt. Ltd.{" "}
      </Link>
      <img
        src="https://www.seawindsolution.ae/assets/front/images/loaderimage.png"
        alt=""
        className="animate-spin"
      />
    </div>
  );
};

export default Footer;
