import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Component/Login";
import Config from "../API/Config";
import Dashboard from "../Component/Dashboard";
import SellTicket from "../Component/SellTicket";

const MainRouter = () => {
  const [isLoggedIn, setIsLoggedIn] = useState("");

  // Check if user is logged in on component mount
  useEffect(() => {
    const usertype = Config.getType;
    setIsLoggedIn(usertype); // Update isLoggedIn based on token presence
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/sponsor/sell/:SponsorId/:Id" element={<SellTicket />} />
    </Routes>
  );
};

export default MainRouter;
